import React, { useState } from "react";
import "./ParametrizedTable.css";

const ParametrizedTable = ({ columns, data }) => {
  const [sortConfig, setSortConfig] = useState(null);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const renderCell = (item, column) => {
    if (column.type === "actions") {
      return (
        <>
          {column.actions.map((action, index) => (
            <button key={index} onClick={() => action.onClick(item,columns)}>{action.label}</button>
          ))}
        </>
      );
    }
    if (column.type === "select" && column.options) {
      const option = column.options.find((opt) => opt.key === item[column.key]);
      return option ? option.value : item[column.key];
    }
    return item[column.key];
  };

  const sortedData = () => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  };

  return (
    <table className="parametrized_table">
      <thead>
        <tr>
          {columns
            .filter((column) => column.showInTable)
            .sort((a, b) => a.order - b.order)
            .map((column) => (
              <th
                key={column.key}
                onClick={() =>
                  column.type !== "actions" && requestSort(column.key)
                }
                className={`${column.type} ${getClassNamesFor(column.key)}`}
              >
                {column.label}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {sortedData().map((item) => (
          <tr key={item._id}>
            {columns
              .filter((column) => column.showInTable)
              .sort((a, b) => a.order - b.order)
              .map((column) => (
                <td key={column.key} className={column.type}>
                  {renderCell(item, column)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ParametrizedTable;
