export const generateColumns = (data, overrides = []) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  const sampleRecord = data[0];
  const defaultExclusions = ["__v", "_id"];
  const nonEditableFields = ["createdAt", "updatedAt", "lastPing", "lastLogin"];

  const columns = Object.keys(sampleRecord)
    .filter((key) => !defaultExclusions.includes(key))
    .map((key, index) => ({
      key,
      label: key,
      type: nonEditableFields.includes(key) ? "string" : "string",
      editable: !nonEditableFields.includes(key),
      order: index, // Default order based on array index
      showInTable: true, // Default showInTable to true
    }));

  // Apply overrides
  overrides.forEach((override) => {
    const index = columns.findIndex((col) => col.key === override.key);
    if (index > -1) {
      columns[index] = { ...columns[index], ...override };
    } else {
      columns.push(override);
    }
  });

  // Sort columns based on order
  columns.sort((a, b) => a.order - b.order);

  return columns;
};
