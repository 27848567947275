import React, { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [panelInfo, setPanelInfo] = useState(null);

  return (
    <DataContext.Provider
      value={{
        panelInfo,
        setPanelInfo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
