import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../Admin/Header";
import Sidebar from "../Admin/Sidebar";
import "./Admin.css";
import AdminDashboard from "../Sections/AdminDashboard";
import Users from "../Sections/Users";
import Settings from "../Sections/Settings";
import NotFound from "../Sections/NotFound";
import PanelInfo from "../Admin/PanelInfo";
import { DataContext } from "../../context/DataContext";

const Admin = () => {
  const { panelInfo } = useContext(DataContext);

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content-container">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        {panelInfo && <PanelInfo info={panelInfo} />}
      </div>
    </div>
  );
};

export default Admin;
