import React, { useContext, useState } from "react";
import { login as loginService } from "../../services/authService";
import { AuthContext } from "../../context/AuthContext";

const Home = () => {
  const { setNeedLogin } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginService(username, password);
      setNeedLogin(false);
      setError(null);
    } catch (error) {
      setNeedLogin(true);
      setError(error.message);
    }
  } 

  return (
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the Admin Panel</p>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            value={username}
            autoComplete='current-username'
            onChange={(e) => setUsername(e.target.value)}
            placeholder='Username'
            required
          />
        </div>
        <div>
          <input
            type="password"
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
            required
          />
        </div>
        {error && <p>{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Home;
