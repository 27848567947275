import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTachometerAlt, FaUsers, FaCog, FaBars } from "react-icons/fa";
import "./Sidebar.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "close"}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        <FaBars />
      </div>
      <Link className="sidebar-item" to="/dashboard">
        <FaTachometerAlt /> {isOpen && <span>Dashboard</span>}
      </Link>
      <Link className="sidebar-item" to="/users">
        <FaUsers /> {isOpen && <span>Users</span>}
      </Link>
      <Link className="sidebar-item" to="/settings">
        <FaCog /> {isOpen && <span>Settings</span>}
      </Link>
    </div>
  );
};

export default Sidebar;
