import { adminApi } from "./api";

export const getUserList = async () => {
  const response = await adminApi.get("/users");
  return response.data;
}; 

export const updateUser = async (userId, data) => {
  const response = await adminApi.put(`/users/${userId}`, data);
  return response.data;
}