import axios from 'axios';

// Configurazione base per Axios
export const api = axios.create({
  baseURL: 'https://server.tenpennynovels.com/api',
  withCredentials: true,
});

export const adminApi = axios.create({
  baseURL: 'https://server.tenpennynovels.com/admin',
  withCredentials: true,
});