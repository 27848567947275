import { api } from "./api";

export const login = async (username, password) => {
  const response = await api.post("/auth/login", { username, password });
  return response.data;
}; 

export const verifyToken = async () => {
  try {
    const response = await api.get("/auth/verify-token");
    return response.data;
  } catch (error) {
    throw error;
  }
};
