import React, { useCallback, useContext, useEffect, useState } from "react";
import { getUserList, updateUser } from "../../services/userService";
import ParametrizedTable from "../Helpers/ParametrizedTable";
import { generateColumns } from "../../utils/generateColumns";
import { DataContext } from "../../context/DataContext";

const Users = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const { setPanelInfo } = useContext(DataContext);

  const handleSave = useCallback(
    async (item, columns) => {
      console.log("Save", item, columns);
      const editRowId = item._id;
      const response = await updateUser(editRowId, item);
      if (response) {
        setData((prevData) =>
          prevData.map((i) =>
            i._id === editRowId ? { ...i, ...item } : i
          )
        );
        setPanelInfo(null);
      }
    },
    [setPanelInfo]
  );

  const handleEdit = useCallback(
    (item,columns) => {
      console.log("handleEdit", item, columns);
      setPanelInfo({
        title: `Modifica ${item.username}`,
        type: "edit",
        columns: columns,
        data: item,
        actions: [
          {
            type: "button",
            label: "Salva",
            onClick: handleSave,
          },
          {
            type: "button",
            label: "Annulla",
            onClick: () => {
              setPanelInfo(null);
            },
          },
        ],
      });
    },
    [handleSave, setPanelInfo]
  );

  const handleShowDetail = useCallback(
    (item,columns) => {
      console.log("handleShowDetail", item,columns);
      setPanelInfo({
        title: `Informazioni ${item.username}`,
        type: "details",
        columns: columns,
        data: item,
      });
    },
    [setPanelInfo]
  );

  useEffect(() => {
    const fetchUsers = async () => {
      let response = await getUserList();
      if (!response[0]) {
        response = [];
      }

      const overrides = [
        {
          key: "userRole",
          label: "User Role",
          type: "select",
          options: [
            { key: "user", value: "User" },
            { key: "admin", value: "Admin" },
          ],
        },
        {
          key: "actions",
          label: "Actions",
          type: "actions",
          order: 999,
          showInTable: true,
          actions: [
            {
              type: "button",
              label: "Edit",
              onClick: handleEdit,
            },
            {
              type: "button",
              label: "Detail",
              onClick: handleShowDetail,
            },
          ],
        },
      ];

      setData(response);
      setColumns(generateColumns(response, overrides));
    };

    if (data === null) {
      fetchUsers();
    }
  }, [handleSave, handleEdit, handleShowDetail, columns, setPanelInfo, data]);

  return (
    <div>
      <h1>Users</h1>
      {data && data.length > 0 && (
        <ParametrizedTable columns={columns} data={data} />
      )}
    </div>
  );
};

export default Users;
