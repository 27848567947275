import React, { useContext,  useState } from "react";
import { DataContext } from "../../context/DataContext";
import "./PanelInfo.css";

const PanelInfo = ({ info }) => {
  const { setPanelInfo } = useContext(DataContext);
  const [editData, setEditData] = useState(info.data)

  const handleClosePanel = () => {
    setPanelInfo(null);
  };

  const handleEditChange = (e, key) => {
    setEditData({
      ...editData,
      [key]: e.target.value,
    });
  };

  console.log('info', info)
 
  const renderInfoData = () => {
    return (
      <div className="panel_infodata">
        {info.columns.filter((col) => col.key !== 'actions').map((col) => (
          <div key={col.key} className="panel-field">
            <strong>{col.label}:</strong>
            {info.type === "edit" && col.editable ? (
              col.type === "select" ? (
                <select
                  value={editData[col.key]}
                  onChange={(e) => handleEditChange(e, col.key)}
                >
                  {col.options.map((opt) => (
                    <option key={opt.key} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  value={editData[col.key]}
                  onChange={(e) => handleEditChange(e, col.key)}
                />
              )
            ) : (
              <span>{editData[col.key]}</span>
            )} 
          </div>
        ))}
          <div className="panel-actions">
            {info.actions && info.actions.map((action) => (
              <button key={action.label} onClick={() => action.onClick(editData, info.columns)}>
                {action.label}
              </button>
            ))}
          </div>
      </div>
    );
  };

  return (
    <div className="panel-container open">
      <div className="panel-content">
      <h2><strong>{info?.title}</strong>
        <button className="close-panel" onClick={handleClosePanel}>
          Chiudi
        </button>
        </h2>
        <div className="panel-info">
                  {info && renderInfoData()}
                </div>
      </div>
    </div>
  );
};

export default PanelInfo;
