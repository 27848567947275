import React, { createContext, useState, useEffect } from "react";
import { verifyToken } from "../services/authService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [needLogin, setNeedLogin] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await verifyToken();
        setNeedLogin(false);
        setIsAuthenticated(true);
      } catch (error) {
        setNeedLogin(true);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [needLogin, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        needLogin,
        isAuthenticated,
        setNeedLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
