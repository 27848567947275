import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home";
import Admin from "./components/Pages/Admin"; 
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./context/AuthContext";

const App = () => {
  const { needLogin, isAuthenticated } = useContext(AuthContext);

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route
          path="/*"
          element={
            isAuthenticated && !needLogin ? <Admin /> : <Home />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
