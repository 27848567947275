import React from 'react';

const Settings = () => {
  return (
    <div>
      <h1>Settings</h1>
      <p>This is another page.</p>
    </div>
  );
};

export default Settings;
